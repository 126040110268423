import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { Wrapper } from '../components/reusable'
import CfaBanner from '../components/cfaBanner'
import SingleSignatureContainer from '../components/singleSigContainer'
import { apiErrors } from '../helpers/apiErrors'
import PocView from '../components/pocView'
import { useUpdateClaimData } from '../claimProvider'

export default function CfaLitigation() {
    const [cfaData, setCfaData] = useState([])
    const [retrievedCfa, setRetrievedCfa] = useState(false)
    const [currentClaim, setCurrentClaim]: any = useState({})
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()
    useEffect(() => {
        if (!retrievedCfa) {
            Loading.standard('Loading...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/litigation-cfa`, { token })
                .then((response) => {
                    const responseData = response.data
                    if (responseData == 'CFA Complete') {
                        Report.success('Document Signed', 'Your Document has already been signed.', 'Okay', () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        })
                    }

                    const signatureData = responseData.sigData
                    console.log(signatureData)

                    if (!signatureData) throw new Error(responseData)
                    localStorage.setItem('name', `${signatureData.first_name} ${signatureData.last_name}`)

                    setCfaData(signatureData)
                    setCurrentClaim(signatureData)
                    setRetrievedCfa(true)
                    updateClaim(signatureData)

                    Loading.remove()
                })
                .catch((e) => {
                    console.error('error:', e)
                    const serverError = e.response.data
                    Loading.remove()
                    apiErrors(serverError)
                })
        }
    }, [])

    const completeCfa = (obj: any) => {
        Report.success(
            'Contingency Fee Agreement Complete',
            'Thank you for signing your document. We will update you on your cases in due course.',
            'Okay',
            () => {
                window.location.href = 'https://sentinellegal.co.uk'
            }
        )

        return
    }

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <CfaBanner />
            <Wrapper>
                <PocView>
                    {retrievedCfa ? (
                        <SingleSignatureContainer
                            signedAction={completeCfa}
                            previewUrl={currentClaim.doc_url}
                            extraPayload={{
                                type: 'litigation-cfa-signature',
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </PocView>
            </Wrapper>
        </div>
    )
}
