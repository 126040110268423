import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { useEffect, useState } from 'react'
import { SubmitButton } from '../reusable'
import { ScrollToTop } from '../layout'

export default function VideoSection() {
    return (
        <>
            <div className={`${styles.videoContainer} ${responsiveStyles.videoContainer}`}>
                <h2>Open Banking with Armalytix</h2>
                <div className={styles.videoContainerInner}>
                    <video controls>
                        <source
                            src="https://armalytix.com/app/uploads/2021/10/Armalytix-Customer-UHD-4K.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
                {/* <SubmitButton text="Go To Consent" btnType="primary" onClick={(e) => window.scrollTo(0, 0)} /> */}
            </div>
        </>
    )
}
