import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { useEffect, useState } from 'react'
import { SubmitButton } from '../reusable'
import { ScrollToTop } from '../layout'

export default function HalfContent({ content, contentTitle, img, reverse, button = null }: any) {
    const [title, setTitle] = useState<String>('')
    const [text, setText] = useState<String>('')
    const [pic, setPic] = useState<any>(img)
    const [isReversed, setIsReversed] = useState<any>(reverse)

    useEffect(() => {
        console.log('contentTitle : ', contentTitle)
        setTitle(contentTitle)
        setText(content)
    }, [content, contentTitle])

    return (
        <>
            <div
                className={`${styles.halfSection} ${responsiveStyles.halfSection} ${
                    isReversed ? `${styles.flipContent} ${responsiveStyles.flipContent}` : ''
                }`}
            >
                <div className={styles.content}>
                    <h3>{title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: `${text}` }}></p>

                    {button && <SubmitButton text="Opt Out" btnType="primary" onClick={(e) => button()} />}
                </div>
                <div>
                    <img src={pic} />
                </div>
            </div>
        </>
    )
}
