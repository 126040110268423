import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './components/layout/scrollTop/scrollToTop'

import Main from './containers/main'
import Foa from './containers/foa'
import Poc from './containers/poc'
import PocV3 from './containers/pocv3'
import Ex160 from './containers/ex160'
import Part36 from './containers/part36'
import NotPart36 from './containers/notPart36'
import Cfa from './containers/cfa'
import FoaV2 from './containers/foaV2'
import CfaTransfer from './containers/cfaTransfer'
import CfaTransferOA from './containers/cfaTransferOA'
import IdUploadOA from './containers/idUploadOA'
import OpenLetter from './containers/openLetter'
import CfaUpdateOpopClients from './containers/cfaUpdateOpopClients'
import ConsentOpenBanking from './containers/consentOpenBanking'
import CfaLitigation from './containers/cfa-litigation'

export default function AppRouter() {
    // Router
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route index element={<Foa />} />
                    <Route path="foa" element={<Foa />} />
                    <Route path="foa/:token" element={<Foa />} />
                    <Route path="foa-v2" element={<FoaV2 type={'foa-v2-signature'} />} />
                    <Route path="foa-v2/:token" element={<FoaV2 type={'foa-v2-signature'} />} />
                    <Route path="foa-v2-additional" element={<FoaV2 type={'foa-v2-additional-signature'} />} />
                    <Route path="foa-v2-additional/:token" element={<FoaV2 type={'foa-v2-additional-signature'} />} />
                    <Route
                        path="foa-v2-resign/:token"
                        element={<FoaV2 type={'foa-v2-additional-signature-resign'} />}
                    />
                    <Route path="poc" element={<Poc />} />
                    <Route path="poc/:token" element={<Poc />} />
                    <Route path="poc-new" element={<Poc />} />
                    <Route path="poc-new/:token" element={<Poc />} />
                    <Route path="poc-resend" element={<Poc />} />
                    <Route path="poc-resend/:token" element={<Poc />} />
                    <Route path="pocv3-new" element={<PocV3 />} />
                    <Route path="pocv3-new/:token" element={<PocV3 />} />
                    <Route path="pocv3-resend" element={<PocV3 />} />
                    <Route path="pocv3-resend/:token" element={<PocV3 />} />
                    <Route path="ex160" element={<Ex160 />} />
                    <Route path="ex160/:token" element={<Ex160 />} />
                    <Route path="part36" element={<Part36 />} />
                    <Route path="part36/:token" element={<Part36 />} />
                    <Route path="not-part36" element={<NotPart36 />} />
                    <Route path="not-part36/:token" element={<NotPart36 />} />
                    <Route path="cfa" element={<Cfa />} />
                    <Route path="cfa/:token" element={<Cfa />} />
                    <Route path="cfa-transfer" element={<CfaTransfer />} />
                    <Route path="cfa-transfer/:token" element={<CfaTransfer />} />
                    <Route path="cfa-transfer-oa" element={<CfaTransferOA />} />
                    <Route path="cfa-transfer-oa/:token" element={<CfaTransferOA />} />
                    <Route path="cfa-update/" element={<CfaUpdateOpopClients />} />
                    <Route path="cfa-update/:token" element={<CfaUpdateOpopClients />} />
                    <Route path="id-upload-oa/:token" element={<IdUploadOA />} />
                    <Route path="open-letter" element={<OpenLetter />} />
                    <Route path="affordability-consent/:token" element={<ConsentOpenBanking />} />
                    <Route path="cfa-litigation/:token" element={<CfaLitigation />} />
                </Route>
            </Routes>
        </Router>
    )
}
